<template>
  <b-card>
    <b-row>
      <b-button
        variant="adn"
        class="btn-filter"
        @click="toggleFilters"
      >
        {{ showFilters ? $t('hideFilter') : $t('showFilter') }}
        <feather-icon
          icon="FilterIcon"
          class="ml-50"
        />
      </b-button>
    </b-row>

    <b-row
      v-if="showFilters"
      class="filter-section"
    >
      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <g-field
          :value.sync="filter.dealerId"
          :dir="isRight ? 'rtl' : 'ltr'"
          label-text="supplier"
          field="select"
          name="dealer"
          :options="suppliers"
          label="arabicName"
          @input="() => {
            $refs['items-table'].refreshTable();
          }"
          @change="() => {
            $refs['items-table'].refreshTable();
          }"
        />
      </b-col>
      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <label
          class="dateLabel"
          for="example-datepicker"
        >{{
          $t('fromDate')
        }}</label>
        <b-form-datepicker
          id="example-datepicker"
          v-model="filter.FromDate"
          locale="ar"
          :date-format-options="{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }"
          class="mb-2"
          name="fromDate"
          @input="() => {
            $refs['items-table'].refreshTable();
          }"
        />
      </b-col>
      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <label
          class="dateLabel"
          for="example-datepicker2"
        >{{
          $t('toDate')
        }}</label>
        <b-form-datepicker
          id="example-datepicker2"
          v-model="filter.ToDate"
          locale="ar"
          :date-format-options="{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }"
          class="mb-2"
          name="toDate"
          @input="() => {
            $refs['items-table'].refreshTable();
          }"
        />
      </b-col>
      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <g-field
          id="code"
          v-silent-search
          label-text="reference"
          :value.sync="filter.ReferenceNumber"
          class="mb-0"
          name="code"
          @keydown.enter="
            (v) => {
              $refs['items-table'].refreshTable();
            }
          "
        />
      </b-col>
      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <g-field
          id="code"
          v-silent-search
          label-text="code"
          :value.sync="filter.Code"
          class="mb-0"
          name="code"
          @keydown.enter="
            (v) => {
              $refs['items-table'].refreshTable();
            }
          "
        />
      </b-col>
      <!-- <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <label
          class="d-block"
          style="margin-bottom: 7px; font-size: 14px;"
        >
          {{ this.$t('transactionType') }}
        </label>
        <b-form-radio-group
          v-model="filter.transactionType"
          button-variant="outline-warning"
          :options="transactionTypesOptions"
          class="filter-buttons"
          buttons
          name="radios-btn-default"
          @change="() => {
            $refs['items-table'].refreshTable();
          }"
        />
      </b-col> -->
      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <label
          class="d-block"
          style="margin-bottom: 7px; font-size: 14px;"
        >
          {{ this.$t('invoicePaymentSituation') }}
        </label>
        <b-form-radio-group
          v-model="filter.itemTransactionPaymentNature"
          button-variant="outline-warning"
          :options="paymentTypesOptions"
          class="filter-buttons"
          buttons
          name="radios-btn-default"
          @change="() => {
            $refs['items-table'].refreshTable();
          }"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col
        md="12"
        sm="12"
        class="text-right pt-2 pb-1"
      >
        <div class="d-flex align-items-center justify-content-end mb-1 mb-md-0">
          <vue-excel-xlsx
            :data="itemsArray"
            :columns="tableColumns"
            :filename="this.$route.name"
            :sheetname="'xlsxSheet'"
            class="btn btn-relief-success p-0 ptn-sm"
          >
            <img
              src="@/assets/images/icons/xls.jpg"
              alt=""
              style="height: 39px;width: auto;"
            >
          </vue-excel-xlsx>
          <b-button
            variant="relief-danger"
            class="ml-1 p-0 ptn-sm"
            @click="printDocument"
          >
            <img
              src="@/assets/images/icons/pdf.jpg"
              alt=""
              style="height: 39px;width: auto;"
            >
          </b-button>
        </div>
      </b-col>
    </b-row>
    <g-table
      ref="items-table"
      :items="invoiceProvider"
      :columns="tableColumns"
      :is-busy="isTableBusy"
      foot-clone
      :noAction="true"
      perPage="25"
      :totalRows="totalRows"
      :createButton="{ visiable: false }"
      :excelButton="{ visiable: false }"
      :pdfButton="{ visiable: false }"
      :searchInput="{ visiable: false }"
    >
      <template
        v-if="hideActions === false"
        #actions="{ item }"
      >
        <feather-icon
          :id="`invoice-row-${item.id}-prev-icon`"
          icon="EyeIcon"
          data-action-type="preview"
          class="mx-1 clickable "
          :hidden="true"
          @click=" () => { editItem(item) }"
        />
        <b-tooltip
          :title="$t('preview')"
          placement="bottom"
          :target="`invoice-row-${item.id}-prev-icon`"
        />
        <div
          class="text-nowrap"
          :set="isConsumed = item.isUsed"
        >
          <template v-if="item.transactionType === 'purch' && !item.isComplete">
            <!-- create addition Order -->
            <b-button
              v-if="!currentBranch.immediatelyUpdateItemBalanceOnPurch"
              v-b-tooltip.hover.top="$t('createAdditionOrder')"
              v-permission=" 'addAdditionOrders' "
              variant="flat-secondary"
              data-action-type="edit"
              class="btn-icon"
              size="sm"
              @click="() => {
                $router.push({
                  name: 'addition-order-new',
                  query: { sourceTransactionId: item.id, sourceTransactionType: item.transactionType },
                });
              }"
            >
              <feather-icon
                :id="`invoice-row-${item.id}-fileText-icon`"
                icon="FileTextIcon"
                class="clickable"
              />
            </b-button>
            <!-- create return Sales -->
            <!-- <b-button
              v-b-tooltip.hover.top="$t('createReturnPurch')"
              v-permission=" 'addReturnPurchTransactions' "
              variant="flat-secondary"
              class="btn-icon"
              data-action-type="edit"
              size="sm"
              @click="() => {
                $router.push({
                  name: 'return-purch-new',
                  query: { sourceTransactionId: item.id, sourceTransactionType: item.transactionType },
                });
              }"
            >
              <feather-icon
                :id="`invoice-row-${item.id}-fileText-icon`"
                icon="RefreshCwIcon"
                class="clickable"
              />
            </b-button> -->
          </template>
          <template
            v-if="(item.transactionType === 'purchOrder') && !item.isComplete"
          >
            <!-- create addition Order -->
            <b-button
              v-if="item.isClosed === false && (profile.isAdmin || (!profile.isAdmin && (profile.permissions.indexOf('addPurchOrders') !== -1
                || profile.permissions.indexOf('addPurchTransactions') !== -1)))"
              v-b-tooltip.hover.top="$t('convertToInovice')"
              data-action-type="edit"
              variant="flat-secondary"
              class="btn-icon"
              size="sm"
              @click="() => {
                $router.push({
                  name: 'purchInvoice',
                  query: { sourceTransactionId: item.id, sourceTransactionType: item.transactionType },
                });
              }"
            >
              <feather-icon
                :id="`invoice-row-${item.id}-fileText-icon`"
                icon="RepeatIcon"
                class="clickable"
              />
            </b-button>
          </template>
          <b-button
            v-if="profile.isAdmin || (!profile.isAdmin && (profile.permissions.indexOf('editPurchOrders') !== -1
              || profile.permissions.indexOf('editPurchTransactions') !== -1))"
            v-b-tooltip.hover.top="$t('edit')"
            data-action-type="edit"
            variant="flat-primary"
            class="btn-icon"
            size="sm"
            @click=" () => { editItem(item) }"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-preview-icon`"
              icon="EditIcon"
            />
          </b-button>
          <b-button
            v-b-tooltip.hover.top="$t('print')"
            variant="flat-primary"
            class="btn-icon"
            size="sm"
            @click="print(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-preview-icon`"
              icon="PrinterIcon"
            />
          </b-button>
          <b-button
            v-if="(item.transactionType === 'purch' || item.transactionType === 'returnPurch') && item.transactionChannel === 'normal'"
            v-b-tooltip.hover.top="item.attachmentUrl !== null ? $t('hasAttachMents') : $t('hasNotAttachMents')"
            v-permission=" 'editPurchTransactions' "
            data-action-type="download"
            variant="flat-primary"
            class="btn-icon"
            size="sm"
            @click="(v) => {
              if (item.attachmentUrl !== null && (item.transactionType === 'purch' || item.transactionType === 'returnPurch') && item.transactionChannel === 'normal') {
                downloadItem(item)
              }
            }"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-attach-icon`"
              :icon="item.attachmentUrl !== null && (item.transactionType === 'purch' || item.transactionType === 'returnPurch') && item.transactionChannel === 'normal' ? 'FolderPlusIcon' : 'FolderIcon'"
            />
          </b-button>
          <b-button
            v-if="profile.isAdmin || (!profile.isAdmin && (profile.permissions.indexOf('deletePurchOrders') !== -1
              || profile.permissions.indexOf('deletePurchTransactions') !== -1))"
            v-b-tooltip.hover.top="$t('delete')"
            :disabled="isConsumed || item.isComplete || item.isPosted"
            data-action-type="delete"
            variant="flat-danger"
            class="btn-icon"
            size="sm"
            @click="(v) => { remove(item) }"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-delete-icon`"
              icon="TrashIcon"
              stroke="red"
              class="danger"
            />
          </b-button>
        </div>
      </template>
    </g-table>
  </b-card>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.5.3/jspdf.min.js"></script>
<script src="@/pages/Shared/html2canvas.js"></script>
<script>
import saveAs from 'file-saver';
import { purchInvoiceTypes, purchInvoiceFilters } from '@/libs/acl/Lookups';
import GTable from '@/pages/Shared/Table.vue';
import reportMixin from '@/mixin/reportMixin';

export default {
  components: {
    GTable,
  },
  mixins: [reportMixin],
  data() {
    return {
      filter: {
        transactionType: 'purch',
        itemTransactionPaymentNature: '',
        id: null,
        dealerId: null,
        storeId: null,
        ItemId: null,
        FromDate: null,
        toDate: null,
        orderClause: null,
        category: 'purch',
        code: null,
        ReferenceNumber: null
      },
      isTableBusy: false,
      items: [],
      totalRows: 0,
      purchInvoiceTypes,
      purchInvoiceFilters,
      totalRemainder: 0,
      totalPaid: 0,
      totalNet: 0,
      itemsArray: [],
      hideActions: false,
      suppliers: [],
      fiscalYearStart: '',
      fiscalYearEnd: '',
      showFilters: false,
    };
  },
  computed: {
    transactionTypesOptions() {
      return purchInvoiceTypes.map((item) => {
        return {
          text: this.isRight ? item.arabicName : item.englishName,
          value: item.id,
        }
      });
    },
    paymentTypesOptions() {
      return purchInvoiceFilters.map((item) => {
        return {
          text: this.isRight ? item.arabicName : item.englishName,
          value: item.id,
        }
      });
    },
    tableColumns() {
      return [
        {
          key: 'code',
          field: 'code',
          label: this.$t('code'),
          sortable: true,
        },
        {
          key: 'transactionType',
          field: 'transactionType',
          label: this.$t('transactionType'),
          sortable: true,
          formatter: (key, value, item) => {
            const name = this.isRight ? item.transArabicName : item.transEnglishName;
            if (item.transactionType === 'purch') return `<span class="badge badge-primary">${name}</span>`;
            if (item.transactionType === 'purchOrder') return `<span class="badge badge-info">${name}</span>`;
            if (item.transactionType === 'returnPurch') return `<span class="badge badge-success">${name}</span>`;
            return `<span class="badge badge-danger">${name}</span>`;
          },
        },
        {
          key: 'transactionDate',
          field: 'transactionDate',
          label: this.$t('transactionDate'),
          sortable: true,
        },
        {
          key: this.isRight ? 'dealerArabicName' : 'dealerEnglishName',
          field: this.isRight ? 'dealerArabicName' : 'dealerEnglishName',
          label: this.$t('dealer'),
          sortable: true,
        },
        {
          key: 'paymentType',
          field: 'paymentType',
          label: this.$t('paymentType'),
          sortable: true,
          formatter: (key, value, item) => {
            if (item.paymentType === 'cash') return `<span class="badge badge-primary">${this.$t('cash')}</span>`;
            return `<span class="badge badge-danger">${this.$t('creditt')}</span>`;
          },
        },
        {
          key: 'net',
          field: 'net',
          label: this.$t('net'),
          sortable: true,
          footer: () => this.fraction(this.totalNet)
        },
        {
          key: 'paid',
          field: 'paid',
          label: this.$t('paid'),
          sortable: false,
          // tdClass: this.addTdClass,
          footer: () => this.fraction(this.totalPaid)
        },
        {
          key: 'remainder',
          field: 'remainder',
          label: this.$t('remaindered'),
          sortable: false,
          // tdClass: this.addTdClass,
          footer: () => this.fraction(this.totalRemainder)
        },
        {
          key: 'updatedStatus',
          field: 'updatedStatus',
          label: this.$t('status'),
          sortable: false,
          formatter: (key, value, tanx) => {
            if (tanx.transactionType === 'purch' && tanx.codeList) return `<span class="badge bg-success bg-darken-1">${this.$t('alreadyDone') + tanx.codeList}</span>`;
            if (tanx.isComplete) return `<span class="badge bg-success bg-darken-1">${this.$t('complete')}</span>`;
            if (tanx.isPosted) return `<span class="badge bg-secondary bg-darken-1">${this.$t('posted')}</span>`
            if (tanx.isUsed) return `<span class="badge bg-warning bg-darken-1">${this.$t('used')}</span>`;
            if (tanx.sourceTransactionId && tanx.sourceTransactionCode && tanx.transactionType === 'returnPurch') return `<span class="badge border-0  bg-info bg-darken-1">${this.$t('invoiceChild') + tanx.sourceTransactionCode} </span>`;
             if (tanx.codeList) return `<span class="badge border-0  bg-info bg-darken-1">${this.$t('child') + tanx.codeList} </span>`;
            return `<span class="badge bg-primary bg-darken-1">${this.$t('newTrans')}</span>`;
          },
        },
        {
          key: 'actions',
        },
      ];
    },
  },
  beforeMount() {
    this.fiscalYearStart = JSON.stringify(this.currentYear) !== '{}' ? this.currentYear.startDate : this.company.fiscalYearStart;
    this.fiscalYearEnd = JSON.stringify(this.currentYear) !== '{}' ? this.currentYear.endDate : this.company.fiscalYearEnd;
  },
  mounted() {
    this.filter.FromDate = this.getDate(this.fiscalYearStart)
    this.filter.ToDate = this.getDate(this.fiscalYearEnd)
    this.getSuppliers();
  },
  methods: {
    toggleFilters() {
      this.showFilters = !this.showFilters;
    },
    downloadItem(item) {
    const path = item.attachmentUrl.split('.')[1];
    saveAs(`${this.baseUrl}${item.attachmentUrl}`, `attached-file.${path}`);
  },
    getSuppliers() {
      this.get({ url: 'Suppliers/summary' }).then((data) => {
          this.suppliers = data;
      });
    },
    hide() {
      this.hideActions = true
    },
    pdfExport(name) {
      html2canvas(document.getElementById('mainTable')).then(function(canvas){
      var imgData = canvas.toDataURL('image/png');
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      var doc = new jsPDF('p', 'mm');
      var position = 0;

      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
          doc.save(`${name}.pdf`);
      });
    },
    printDocument() {
      this.hide()
        setTimeout(() => {this.pdfExport(this.$route.name)
        this.hideActions = false
        }, 500);
    },
      addTdClass(value, key, item) {
         if (this.filter.transactionType === 'purchOrder') {
           return 'd-none'
         } if (item.transactionType === 'purchOrder') {
         return 'not-visible';
      }
      return null
      },
    remove(item) {
      this.confirmAction(
        {
          text: this.$t('areYouSureYouWantToDelete'),
        },
        () => {
          this.delete({ url: `ItemTransactions/${item.transactionType}`, id: item.id }).then(() => {
            this.doneAlert({ text: this.$t('deletedSuccessfully') });
            this.$refs['items-table'].refreshTable();
          });
        }
      );
    },
    editItem(item) {
      let name = 'purchInvoice-edit';
      if (item.transactionType === 'purchOrder') name = 'purchOrder-edit';
      if (item.transactionType === 'returnPurch') name = 'return-purch-edit';
      else if (item.transactionType === 'addition-order') name = 'addition-order-edit';
      this.$router.push({
        name,
        params: { id: item.id },
      });
    },
    invoiceProvider(ctx, callback) {
      const {
        currentPage, perPage, sortBy, sortDesc
      } = ctx;

      this.filter.OrderClause = this.orderQuery(sortBy, sortDesc);
      let params = `/Summary?pageNumber=${currentPage}&pageSize=${perPage}&`;

      params += this.getFilterObj(this.filter);

      this.isTableBusy = true;
      this.get({ url: `itemTransactions/categories/purch${params}` })
        .then(({ data, totalCount }) => {
          this.isTableBusy = false;
          this.totalRows = totalCount === 0 ? 1 : totalCount;
          this.totalNet = 0;
          this.totalRemainder = 0;
          this.totalPaid = 0;
          data.forEach((item) => {
            // item.paid = item.payments.reduce((total, val) => {
            //   total += val.value;
            //   return total;
            // }, 0);
            const transactionName = this.purchInvoiceTypes.find((val) => val.id === item.transactionType) || {};
              const codeList = [];
            const transList = data.filter((i) => item.id === i.sourceTransactionId);
              if (transList.length > 0) {
                transList.forEach((trans) => codeList.push(trans.code))
              }
            item.codeList = transList.length > 0 ? codeList.toString() : '';
            item.transArabicName = transactionName.arabicName;
            item.transEnglishName = transactionName.englishName;
            this.totalPaid += item.paid;
            this.totalNet += item.net;
            this.totalRemainder += (Number(item.net) - Number(item.paid));
            item.paid = this.fraction(item.paid);
            item.remainder = this.fraction(item.net - item.paid);
            item.transactionDate = this.getDate(item.transactionDate);
            item.net = this.fraction(item.net);
            // status
            item.updatedStatus = ''
            if (item.transactionType === 'purch' && item.codeList) item.updatedStatus = `${this.$t('alreadyDone')} ${item.codeList}`;
            if (item.isComplete) item.updatedStatus = `${this.$t('complete')}`;
            if (item.isPosted) item.updatedStatus = `${this.$t('posted')}`
            if (item.isUsed) item.updatedStatus = `${this.$t('used')}`;
            if (item.sourceTransactionId && item.sourceTransactionCode && item.transactionType === 'returnPurch') item.updatedStatus = `${this.$t('invoiceChild')} ${item.sourceTransactionCode} `;
            if (item.codeList) item.updatedStatus = `${this.$t('child')} ${item.codeList} `;
            if (item.updatedStatus === '') item.updatedStatus = `${this.$t('newTrans')}`;
          });
          callback(data);
          this.itemsArray = data;
          const purchData = data.filter(x => x.transactionType === 'purch' && x.transactionChannel === 'normal');
          window.sessionStorage.setItem('cachedPurchInvoices', JSON.stringify(purchData));
        })
        .catch(() => {
          this.isTableBusy = false;
          callback([]);
        });
      return null;
    },
     print(item) {
      const printedItem = {
        id: item.id
      }
      let repName = '';
        if (item.transactionType === 'purch') repName = 'Purchase-ar'
      else if (item.transactionType === 'returnPurch') repName = 'ReturnPurch-ar'
      else if (item.transactionType === 'purchOrder') {
        repName = 'SalesOrder-ar';
        printedItem.transactionType = 7;
      }
       this.printReport(repName, printedItem);
    },
  },
};
</script>
<style>
.not-visible {
  visibility: hidden;
}
.filter-buttons label{
  padding: 0.75rem !important;
}
.filter-section {
  background-color: #f9f9f9;
  margin: 15px 0;
  padding: 25px 0;
  border-radius: 7px;
  border-top-left-radius: 0;
  border: 2px solid #ad8884;
}

.btn-filter {
  margin-left: 14px;
  width: 157px;
  margin-bottom: -16px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
</style>
